import React from "react"
import * as styles from "./Enhancements.module.scss"
import { Container, Row, Col } from "react-bootstrap"

function Enhancements({ strapiData }) {
  return (
    <div className={styles.enhancements}>
      <Container>
        <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }}></h2>
        <Row>
          {strapiData?.cards?.map((e, i) => (
            <Col lg={4}>
             <div className={styles.cards}>
             <img
                src={e?.image1[0]?.localFile?.publicURL}
                // src="https://invozone-backend.s3.us-east-1.amazonaws.com/fi_681443_a23130a038.svg"
                alt="tick"
              />
              <h3
                dangerouslySetInnerHTML={{
                  __html: e?.title,
                }}
              ></h3>

              <p
                className={styles.mainDes}
                dangerouslySetInnerHTML={{
                  __html: e?.subTitle,
                }}
              />
             </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Enhancements
