import { graphql, Script } from "gatsby"
import React from "react"
import ContactSales from "../../components/common/ContactSales"
// import Faqs from "../../components/common/Faqs2"
import MainLayout from "../../layouts/MainLayout"
import Banner from "../../components/invocom/Banner"
import Technologiesweuse from "../../components/invocom/Technologiesweuse"
import AIConsultingService from "../../components/invocom/Introsection"
import KeyFeatures from "../../components/invocom/KeyFeatures"
// import SEORevamp from "../../components/common/SEO_Revamp"
import Conclusion from "../../components/invocom/Conclusion"
import Workflowpredicto from "../../components/invocom/WorkflowPredicto"
import KeyChallengesSolution from "../../components/invocom/KeyChalanges"
import Enhancements from "../../components/invocom/Enhancements"
import Result from "../../components/invocom/Result"
import Efficiency from "../../components/invocom/Efficiency"

export const Head = ({ data }) => {
  // const { metaTitle, metaDescription } = data?.strapiPage?.seo
  // let headSchema = []
  // const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
  //   return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  // })
  console.log("All GraphQL Data:", data)

  // Log specific sections
  console.log("Workflow Data:", data?.allStrapiAiHologram?.nodes[0]?.workflow)
  console.log(
    "Workflow Steps:",
    data?.allStrapiAiHologram?.nodes[0]?.workflow?.workflow_steps?.data
  )
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />

      {/* <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/hire_elixir_developers_f701d7b38c.webp"
      /> */}
    </>
  )
}

const AIDevelopment = ({ data }) => {
  console.log(data)

  const heroSection = data?.strapiPage?.sections[0]

  const consultingService = data?.strapiPage?.sections[1]
  const contributions = data?.strapiPage?.sections[4]
  const keyChallenges = data?.strapiPage?.sections[5]
  const enhancements = data?.strapiPage?.sections[6]
  const result = data?.strapiPage?.sections[7]
  const efficiency = data?.strapiPage?.sections[8]
  const engagementModel = data?.strapiPage?.sections[2]
  const conclusion = data?.strapiPage?.sections[9]
  const faqs = data?.strapiPage?.sections[8]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      <Technologiesweuse hideCurve={true} strapiData={heroSection}/>
      <AIConsultingService strapiData={consultingService} />
      <KeyFeatures strapiData={engagementModel} />
      {/* <Contributions strapiData={contributions} /> */}
      {/* <Workflowpredicto /> */}
      <KeyChallengesSolution strapiData={keyChallenges} />
      <Enhancements strapiData={enhancements} />
      <Result strapiData={result} />
      <Efficiency strapiData={efficiency} />
      <Conclusion strapiData={conclusion} />
      {/* <Faqs strapiData={faqs} pageName="Staff Augmentation Company" /> */}
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query invocom {
  strapiPage(slug: {eq: "invocom"}) {
    sections {
      title
      subTitle
      subTitle2
      cards {
        title
        title2
        subTitle
        subTitle2
        description: childStrapiComponentCardsCardDescriptionTextnode {
          description
        }
        image1 {
          alternativeText
          localFile {
            publicURL
          }
        }
        image2 {
          alternativeText
          localFile {
            publicURL
          }
        }
        buttons {
          title
          url
        }
        jsonData {
          caseServices {
            invocom_caseStudy {
              skills
            }
          }
        }
      }
      secImages {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
          publicURL
        }
        url
      }
      buttons {
        title
        url
      }
      description: childStrapiComponentSectionsSectionDescriptionTextnode {
        id
        description
      }
    }
    buttons {
      url
      title
    }
    seo {
      metaTitle
      metaDescription
      schemas {
        visibilityIn
        structuredData
      }
    }
  }
}
`

export default AIDevelopment
