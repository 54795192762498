import React from "react"
import * as styles from "./Result.module.scss"
import { Container, Row, Col } from "react-bootstrap"

function Enhancements({ strapiData }) {
  return (
    <div className={styles.enhancements}>
      <Container>
        <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }}></h2>
        <Row>
          {strapiData?.cards?.map((e, i) => (
            <Col lg={6} className="d-flex p-0">
              <div className={styles.cards}>
                <span>0{i + 1}</span>
                <h3
                  dangerouslySetInnerHTML={{
                    __html: e?.title,
                  }}
                ></h3>

                <p
                  className={styles.mainDes}
                  dangerouslySetInnerHTML={{
                    __html: e?.subTitle,
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Enhancements
